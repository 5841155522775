import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { formColor } from '../../../../helper';
import Image from '../../../Image';
import imgHelper from '../../../Image/helper';

import styles from './styles.module.css';

class LayoutContainer extends React.PureComponent {

  constructor(props) {

    super(props);

    const { section } = props;
    const layout = section !== undefined ? props.section.styles.layout : undefined;
    const recStyles = section !== undefined ? props.section.styles : undefined;

    const { logo, themeData } = props.pageContext;
    let logoHeight;
    let linkHeight = 34;
    if (logo && logo.active) logoHeight = logo.logoHeight !== undefined ? logo.logoHeight : 60;
    else {

      let lineHeight = 1.5;
      let size = 38;
      if (themeData && themeData.typography && themeData.typography.logo) {

        ({ lineHeight } = themeData.typography.logo);
        size = themeData.typography.logo.fontSize === 'Large'
          ? 50
          : themeData.typography.logo.fontSize === 'Small'
            ? 30
            : size;

      }

      logoHeight = size * lineHeight;

    }

    if (themeData && themeData.typography && themeData.typography.navigation) {

      linkHeight = themeData.typography.navigation.fontSize === 'Small'
        ? 30
        : themeData.typography.navigation.fontSize === 'Large'
          ? 40
          : linkHeight;

    }

    /* jshint ignore:start */

    const componentName = props.pageContext.components[props.section.type];
    // eslint-disable-next-line
    const specificLayout = require(`src/components/EditorPanel/ManageSections/${componentName}`);

    /* jshint ignore:end */

    this.state = {
      section,
      layout,
      recStyles,
      SpecificLayout: specificLayout.default,
      logoHeight,
      linkHeight,
    };

  }

  render() {

    let navHeight = this.state.logoHeight + (this.props.isScrolling
         && this.props.pageContext.navigation.styles.fixed_top ? 8 : 80);
    navHeight = this.props.pageContext.navigation.styles.layout === 'center'
      ? navHeight + this.state.linkHeight + 10
      : this.props.pageContext.navigation.styles.layout === 'divided'
        ? navHeight + 9
        : navHeight;

    let full = '';
    if (
      (this.state.section.styles.full && this.props.matches)
      || !this.props.matches
    ) {

      full = 'Full';

    }

    const { articleMother } = this.props.pageContext;
    const template = this.state.section.type.toUpperCase();

    if (this.state.section !== undefined) {

      let boxStyle;
      if (
        this.state.recStyles.box
        && this.state.recStyles.box.styles
        && this.state.recStyles.box.styles.backgroundColor
        && this.state.recStyles.box.styles.backgroundColor.active
      ) {

        let { backgroundColor } = this.state.recStyles.box.styles;
        if (
          this.state.recStyles.box.styles.backgroundColor.solid === ''
          && this.state.recStyles.box.styles.backgroundColor.gradient.from === ''
        ) {

          const [solid] = this.props.pageContext.themeData.colors;
          backgroundColor = { solid };

        }

        boxStyle = formColor(
          backgroundColor,
          false,
          this.state.recStyles.box.styles.backgroundColor.opacity,
        );

      }

      let overlay;
      if (this.state.recStyles.styles.backgroundColor.active) {

        let { backgroundColor } = this.state.recStyles.styles;
        if (
          this.state.recStyles.styles.backgroundColor.solid === ''
          && this.state.recStyles.styles.backgroundColor.gradient.from === ''
        ) {

          backgroundColor = { solid: '#FFFFFF' };

        }

        overlay = formColor(
          backgroundColor,
          false,
          this.state.recStyles.styles.backgroundColor.opacity,
        );

      }

      let bg;
      if (this.state.recStyles.styles.bg && this.state.recStyles.styles.bg.active) {

        let backgroundColor = this.state.recStyles.styles.bg;
        if (
          this.state.recStyles.styles.bg.solid === ''
          && this.state.recStyles.styles.bg.gradient.from === ''
        ) {

          backgroundColor = { solid: '#FFFFFF' };

        }

        bg = formColor(
          backgroundColor,
          false,
          this.state.recStyles.styles.bg.opacity,
        );

      }

      let size = 'cover';

      if (this.state.recStyles && this.state.recStyles.image) {

        size = this.state.recStyles.image.size || 'cover';

      }

      const type = this.state.section.type.toLowerCase();
      const sectionWrapper = `${type}Wrapper${this.state.layout.charAt(0)
        .toUpperCase()}${this.state.layout.slice(1)}`;

      const bgImageActive = this.state.recStyles.image
        && (this.state.recStyles.image.url || this.state.recStyles.image.id)
        && this.state.recStyles.image.active;

      const story = (this.state.section.type === 'STORY' && this.state.layout !== 'fullLeft' && this.state.layout !== 'fullRight')
        || this.state.section.type !== 'STORY';
      const datasheet = (this.state.section.type === 'DATASHEET' && this.state.layout === 'left') || this.state.section.type !== 'DATASHEET';

      let padding = '';
      let pTop = {};

      if (
        this.state.section.type === 'ARTICLELOOP'
        && this.state.section.styles.layout === 'hero'
        && this.state.section.data[0].active === false
        && this.state.section.data[1].active === false
      ) {

        pTop = { marginTop: '0' };

      } else if (
        story
        && datasheet
        && this.state.section.type !== 'CAROUSEL' && this.state.section.type !== 'MAP'
        && (this.state.recStyles.styles.backgroundColor.active || bgImageActive)
        && this.props.overlay !== true
      ) {

        padding = 'paddingTB120';

      } else if (
        story
        && datasheet
        && this.state.section.type !== 'CAROUSEL' && this.state.section.type !== 'MAP'
        && this.props.overlay !== true
      ) {

        padding = 'paddingTB60';

      } else if (
        story
        && datasheet
        && this.props.overlay === true
        && this.state.section.type !== 'CAROUSEL' && this.state.section.type !== 'MAP'
        && (this.state.recStyles.styles.backgroundColor.active || bgImageActive)
      ) {

        pTop = { paddingTop: `${navHeight + 120}px` };
        padding = 'paddingB120';

      } else if (
        story
        && this.props.overlay === true
        && this.state.section.type !== 'CAROUSEL' && this.state.section.type !== 'MAP'
        && (this.state.recStyles.styles.backgroundColor.active || bgImageActive)
      ) {

        pTop = { paddingTop: `${navHeight + 120}px` };
        padding = 'paddingB120';

      } else if (story && this.props.overlay === true && this.state.section.type !== 'CAROUSEL' && this.state.section.type !== 'MAP') {

        pTop = { paddingTop: `${navHeight + 60}px` };
        padding = 'paddingB60';

      } else if (
        this.props.overlay === true
        && (
          (this.state.section.type === 'STORY' && (this.state.layout === 'fullLeft' || this.state.layout === 'fullRight'))
          || this.state.section.type === 'CAROUSEL'
        )
      ) {

        pTop = { paddingTop: `${navHeight}px` };

      } else if (this.props.overlay === true && this.state.section.type === 'MAP') {

        pTop = { marginTop: `${navHeight}px` };

      }

      if (this.state.section.type === 'HEROSLIDER') {

        const i = this.state.section.data.findIndex(e => e.type === 'DATA');
        const height = i > -1 ? this.state.section.data[i].height : 70;
        pTop.height = `${height}vh`;

      }

      let parallax = (this.state.section.type === 'HERO' && this.state.section.styles.image.parallax) || false;
      let parallaxCSS = {};

      if (parallax) {

        const imageSet = imgHelper.constructParallaxImage(
          this.state.recStyles.image.id,
          this.state.recStyles.image.url || this.state.recStyles.image.src,
          this.state.recStyles.image.CDNLink, this.state.recStyles.image.alt,
          this.props.pageContext.images, this.props.pageContext.stockImages,
        );

        if (imageSet) {

          const bestQ = imgHelper
            .getBestQuality(
              imageSet._id,
              this.props.pageContext.images,
              this.props.pageContext.stockImages,
            );

          parallaxCSS = {
            backgroundImage: `${`url("${bestQ}")`}`,
            backgroundPosition: `${this.state.recStyles.image.position}`,
            backgroundSize: `${this.state.recStyles.image.size}`,
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            opacity: `${this.state.recStyles.image.opacity}`,
          };

        } else {

          parallax = false;

        }

      }

      return (
        <div
          id={`${this.state.section._id}_backgroundSection`}
          className={styles.sectionWrapper}
          style={bg}
        >
          <div className={styles.imgOverlayWrapper}>
            {
              bgImageActive
              && (
                <div
                  style={parallax ? parallaxCSS : null}
                  className={parallax
                    ? styles.sectionBackgroundWrapperParallax : styles.sectionBackgroundWrapper}
                >
                  {
                    !parallax
                    && (
                      <Image
                        id={this.state.recStyles.image.id}
                        url={this.state.recStyles.image.url || this.state.recStyles.image.src}
                        alt={this.state.recStyles.image.alt}
                        position={this.state.recStyles.image.position}
                        opacity={this.state.recStyles.image.opacity}
                        sizes="100vw"
                        size={size}
                        images={this.props.pageContext.images}
                        imageClass={`sectionBackgroundImage${this.state.recStyles.full === true ? 'Full' : ''}`}
                      />
                    )
                  }
                </div>
              )
            }
            {
              overlay
              && (
                <div
                  style={overlay}
                  className={styles[`sectionOverlay${this.state.recStyles.full === true ? 'Full' : ''}`]}
                />
              )
            }
            {
              (
                (
                  this.state.section.type === 'HERO' || this.state.section.type === 'TEAM'
                  || (this.state.section.type === 'QUOTE' && this.state.layout === 'left')
                )
                && this.state.recStyles.box !== undefined
                && this.state.recStyles.box.styles.backgroundColor.active
              )
              && (
                <div className={styles.sectionBoxWrapper}>
                  <div className={styles[`sectionBoxPositioner${this.state.recStyles.full === true ? 'Full' : ''}`]}>
                    <div
                      style={boxStyle}
                      className={styles[`${type}ColorBox`]}
                    />
                  </div>
                </div>
              )
            }
          </div>
          <Link
            name={this.state.section._id}
            id={`#${this.state.section._id}`}
            to={`/#${this.state.section._id}`}
          />
          <div className={`${styles[sectionWrapper]} ${styles[padding]}`} style={pTop}>
            {this.state.SpecificLayout
              ? <this.state.SpecificLayout
                section={this.state.section}
                layout={this.state.layout}
                template={template}
                themeData={this.props.pageContext.themeData}
                full={full}
                articleMother={articleMother}
                siteLanguage={this.props.pageContext.language}
                metaLanguage={this.props.pageContext.metaLanguage}
                images={this.props.pageContext.images}
                badge={this.props.pageContext.badge}
                pagePathList={this.props.pageContext.pagePathList}
                articlePathList={this.props.pageContext.articlePathList}
                filePathList={this.props.pageContext.filePathList}
                buttons={this.props.pageContext.buttons}
                boxStyle={boxStyle}
                matches={this.props.matches}
                padding={pTop.paddingTop}
              /> : null }
          </div>
        </div>
      );

    }

  }

}

LayoutContainer.propTypes = {
  section: PropTypes.shape({}),
  theme: PropTypes.string,
  overlay: PropTypes.bool,
  siteLanguage: PropTypes.string,
  metaLanguage: PropTypes.string,
  badge: PropTypes.shape({}),
  pageContext: PropTypes.shape({}),
  images: PropTypes.arrayOf(PropTypes.shape({})),
  stockImages: PropTypes.arrayOf(PropTypes.shape({})),
};

export default LayoutContainer;
